@import url('https://rsms.me/inter/inter.css');

:root {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color-scheme: dark;
}

body {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100vh;
}

#app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

div {
    min-width: 0px;
}

p {
    margin: 0;
}

h1 {
    margin: 0;
    font-weight: 500;
}

dl, dd, dt {
    padding: 0;
    margin: 0;
    min-width: 0px;
}

button {
    cursor: pointer;
}