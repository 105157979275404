@import "https://rsms.me/inter/inter.css";
:root {
  color-scheme: dark;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

body {
  min-height: 100vh;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  display: flex;
}

#app {
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
}

div {
  min-width: 0;
}

p {
  margin: 0;
}

h1 {
  margin: 0;
  font-weight: 500;
}

dl, dd, dt {
  min-width: 0;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

/*# sourceMappingURL=index.1660fc42.css.map */
